// ExperimentDetail.jsx
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import { FaFilePdf } from 'react-icons/fa';
import apiService from "../../services/apiService";
import AppConfig from "../../AppConfig";
import ImageSlider from "../ImageSlider"; // Import the ImageSlider component

const ExperimentDetail = ({

    flag,
    geneSequence,
    selectedExperiment,
    setSelectedExperiment,
    handleCloseExperimentDetailModal,

}) => {
    const [experiment, setExperiment] = useState(selectedExperiment ?? {});
    const [sopPdfs, setSopPdfs] = useState(selectedExperiment?.sop_pdfs ?? []);
    const [resultImages, setResultImages] = useState(selectedExperiment?.result_images ?? []);
    const [any_deviation, setDeviationInSop] = useState(experiment.any_deviation ?? false);
    const sopInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const [sequenceAnalysisPdfs, setSequenceAnalysisPdfs] = useState(selectedExperiment?.sequence_report_pdfs ?? []);
    const sequenceAnalysisInputRef = useRef(null);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
    const [pdfPreviews, setPdfPreviews] = useState([]);
    const [pdfsToUpload, setPdfsToUpload] = useState([]);
    const [pdfsToUploadReport, setPdfsToUploadReport] = useState([]);
    const [pdfPreviewsReport, setPdfPreviewsReport] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);




    useEffect(() => {
        //..console.log("experiment", experiment);
    }, [experiment]);

    useEffect(() => {
        return () => {
            pdfPreviews.forEach((preview) => URL.revokeObjectURL(preview.url));
            pdfPreviewsReport.forEach((preview) => URL.revokeObjectURL(preview.url));
        };
    }, [pdfPreviews], [pdfPreviewsReport]);


    const getObjUrl = (obj) => {
        if (obj)
            return URL.createObjectURL(obj);
        else
            return null;
    }
    const selectedOpenPdf = (pdf) => {
        const pdfUrl = pdfPreviews.find((preview) => preview.name === pdf)?.url
            ?? `${AppConfig.baseUrl}/uploads/${pdf}`;
        setSelectedPdfUrl(pdfUrl);
    };
    const selectedOpenPdfReport = (pdf) => {
        const pdfUrl = pdfPreviewsReport.find((preview) => preview.name === pdf)?.url
            ?? `${AppConfig.baseUrl}/uploads/${pdf}`;
        setSelectedPdfUrl(pdfUrl);
    };

    const handleInputChange = (chObj) => {
        setExperiment(e => { return { ...e, ...chObj } })
    }
    const handleCancel = () => {
        handleCloseExperimentDetailModal();

    }

    const handleSave = async () => {

        if (pdfsToUpload.length > 0) {
            try {
                const formData = new FormData();
                pdfsToUpload.forEach((file) => formData.append("sopPdfs", file));
                await apiService.post(`/sopPdfs/upload/${selectedExperiment.id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                setPdfsToUpload([]);
            } catch (error) {
                console.error("Error uploading pdfs:", error);
                alert("Failed to upload files. Please try again.");
            }
        }

        if (pdfsToUploadReport.length > 0) {
            try {
                const formData = new FormData();
                pdfsToUploadReport.forEach((file) => formData.append("sequenceReportPdfs", file));
                await apiService.post(`/sequenceReportPdfs/upload/${selectedExperiment.id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                setPdfsToUploadReport([]);
            } catch (error) {
                console.error("Error uploading pdfs:", error);
                alert("Failed to upload files. Please try again.");
            }
        }

        if (filesToUpload.length > 0) {
            try {
                const formData = new FormData();
                filesToUpload.forEach((file) => formData.append("resultImages", file));
                await apiService.post(`/resultImages/upload/${selectedExperiment.id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                setFilesToUpload([]);
            } catch (error) {
                console.error("Error uploading files:", error);
            }
        }
        setSelectedExperiment({
            ...experiment,
            sop_pdfs: sopPdfs,
            result_images: resultImages,
            sequence_report_pdfs: sequenceAnalysisPdfs,

        });
        handleCloseExperimentDetailModal();
    };

    const handleSopFileChange = (e) => {
        const files = Array.from(e.target.files);
        const allowedFiles = files.filter(
            (file) =>
                file.type === "application/pdf"
        );
        if ((allowedFiles.length + sopPdfs.length) > 10) {
            alert("Maximum 10 files allowed.");
            return;
        }
        const renamedFiles = allowedFiles.map((file, index) => {
            const newName = `exp_sop_${experiment.id}_${sopPdfs.length + index + 1}.pdf`;
            return new File([file], newName, { type: file.type });

        });
        const renamedFileNames = renamedFiles.map((file) => file.name);
        const newPreviews = renamedFiles.map((file) => ({
            name: file.name,
            url: URL.createObjectURL(file),
        }));
        setSopPdfs(prev => [...sopPdfs, ...renamedFileNames]);
        setPdfsToUpload((prevFiles) => [...prevFiles, ...renamedFiles]);
        setPdfPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

    };

    const handleSequenceAnalysisFileChange = (e) => {
        const files = Array.from(e.target.files);
        const allowedFiles = files.filter(
            (file) =>
                file.type === "application/pdf"
        );
        if ((allowedFiles.length + sequenceAnalysisPdfs.length) > 10) {
            alert("Maximum 10 files allowed.");
            return;
        }
        const renamedFiles = allowedFiles.map((file, index) => {
            const newName = `exp_sequence_${experiment.id}_${sequenceAnalysisPdfs.length + index + 1}.pdf`;
            return new File([file], newName, { type: file.type });

        });
        const renamedFileNames = renamedFiles.map((file) => file.name);
        const newPreviews = renamedFiles.map((file) => ({
            name: file.name,
            url: URL.createObjectURL(file),
        }));
        setSequenceAnalysisPdfs(prev => [...sequenceAnalysisPdfs, ...renamedFileNames]);
        setPdfsToUploadReport((prevFiles) => [...prevFiles, ...renamedFiles]);
        setPdfPreviewsReport((prevPreviews) => [...prevPreviews, ...newPreviews]);
    };

    const handleImageFileChange = (e) => {

        const _filesToUpload = Array.from(e.target.files);
        // Filter out files that are not jpeg, png, or webp
        const allowedFiles = _filesToUpload.filter(
            (file) =>
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/webp"
        );
        // Limit the number of files to a maximum of 10
        if ((allowedFiles.length + resultImages.length) > 10) {
            alert("Maximum 10 files allowed.");
            return;
        }
        const renamedFiles = allowedFiles.map((file, index) => {
            const newName = `exp_image_${experiment.id}_${resultImages.length + index + 1}.jpg`;
            return new File([file], newName, { type: file.type });
        });
        const renamedFileNames = renamedFiles.map((file) => file.name);
        setResultImages([...resultImages, ...renamedFileNames]);
        setFilesToUpload((prevFiles) => [...prevFiles, ...renamedFiles]);
    };

    const handleRemovePdf = (name, type) => {
        if (type === 'sop') {
            setSopPdfs(e => e.filter(pdf => pdf !== name));
            setPdfsToUpload((prev) =>
                prev.filter((file) => file.name !== name)
            );
            setPdfPreviews((prev) =>
                prev.filter((preview) => preview.name !== name)
            );
        } else if (type === 'sequenceAnalysis') {
            setSequenceAnalysisPdfs(e => e.filter(pdf => pdf !== name));
            setPdfsToUploadReport((prev) =>
                prev.filter((file) => file.name !== name)
            );
            setPdfPreviewsReport((prev) =>
                prev.filter((preview) => preview.name !== name)
            );
        }
    };

    const handleRemoveImage = (name) => {
        setFilesToUpload((prev) =>
            prev.filter((file) => file?.name !== name)
        );
        setResultImages(e => e.filter(item => item !== name));
    };
    const containerStyles = {
        width: "300px",
        height: "280px",
        margin: "0 auto",
    };
    const handleDeviationCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setDeviationInSop(isChecked);
        handleInputChange({ any_deviation: isChecked });
    };

    return (
        <>{experiment && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg w-1/2 max-h-screen overflow-y-auto">
                    <h2 className="text-xl font-bold mb-4">{experiment.experiment_name}</h2>

                    {/* Experiment Name */}
                    <div className="mb-4">
                        <label htmlFor="experiment_name" className="block text-black-700 font-bold mb-1">
                            Experiment Name:
                        </label>
                        <input
                            type="text"
                            id="experiment_name"
                            name="experiment_name"
                            value={experiment.experiment_name ?? experiment.experimentName}
                            onChange={(e) => {
                                handleInputChange({ experiment_name: e?.target?.value });
                            }}
                            className="border border-gray-300 p-2 rounded w-full"
                            required
                            readOnly
                        />

                    </div>

                    {/* Common Name */}
                    <div className="mb-4">
                        <label htmlFor="common_name" className="block text-black-700 font-bold mb-1">
                            Common Name:
                        </label>
                        <input
                            type="text"
                            id="common_name"
                            name="common_name"
                            value={experiment.common_name ?? experiment.commonName ?? ""}
                            onChange={(e) => {
                                handleInputChange({ common_name: e?.target?.value });
                            }}
                            className="border border-gray-300 p-2 rounded w-full"
                            required
                            readOnly

                        />
                    </div>

                    {/* Gene  sequence*/}
                    <div className="mb-4">
                        <label htmlFor="gene" className="block text-black-700 font-bold mb-1">
                            Gene Sequence:
                        </label>
                        <textarea
                            id="gene_sequence"
                            name="gene_sequence"
                            value={flag === 1 ? experiment.gene_sequence : geneSequence ?? ""}
                            /*onChange={(e) => {
                                const updatedExperiments = editedProduct.product_experiments.map((exp) => {
                                    if (exp.id === selectedExperiment.id) {
                                        return { ...exp, gene_sequence: e.target.value };
                                    }
                                    return exp;
                                });
                                setEditedProduct({ ...editedProduct, product_experiments: updatedExperiments });
                                setSelectedExperiment((prev) => ({ ...prev, gene_sequence: e.target.value }));
                            }}*/
                            readOnly // Make the field non-editable
                            className="border border-gray-300 p-2 rounded w-full"
                            placeholder="No Gene Sequence Found"
                        />
                    </div>

                    {/* Protein Sequence */}
                    <div className="mb-4">
                        <label htmlFor="protein" className="block text-black-700 font-bold mb-1">
                            Protein Sequence:
                        </label>
                        <textarea
                            id="protein_sequence"
                            name="protein_sequence"
                            value={experiment.protein_sequence ?? ""}
                            onChange={(e) => {
                                handleInputChange({ protein_sequence: e?.target?.value });

                            }
                            }
                            className="border border-gray-300 p-2 rounded w-full"
                            placeholder="Enter protein sequence"
                        />
                    </div>

                    {/* Experiment Type */}
                    <div className="mb-4">
                        <label htmlFor="experiment_type" className="block text-black-700 font-bold mb-1">
                            Experiment Type:
                        </label>
                        <select
                            id="experiment_type"
                            name="experiment_type"
                            value={experiment.experiment_type ?? ""}
                            onChange={(e) => handleInputChange({ experiment_type: e.target.value })}
                            className="border border-gray-300 p-2 rounded w-full"
                        >
                            <option value="">Select Type</option>
                            <option value="Transformation for Plasmid Library">Transformation for Plasmid Library</option>
                            <option value="Transformation for Expression Vector Library">Transformation for Expression Vector Library</option>
                            <option value="Transformation of the Expression Host">Transformation of the Expression Host</option>
                        </select>
                    </div>

                    {/* Trial Number */}
                    <div className="mb-4">
                        <label htmlFor="trial_number" className="block text-black-700 font-bold mb-1">
                            Trial Number:
                        </label>
                        <input
                            type="number"
                            id="trial_number"
                            name="trial_number"
                            value={experiment.trial_number ?? ""}
                            onChange={(e) => handleInputChange({ trial_number: e.target.value })}
                            className="border border-gray-300 p-2 rounded w-full"
                        />
                    </div>

                    {/* Any Deviation in SOP */}
                    <div className="mb-4">
                        <div className="flex items-center">
                            <label className="block text-black-700 font-bold mb-1 mr-2 ">
                                Any Deviation in SOP:
                            </label>
                            <input
                                type="checkbox"
                                id="any_deviation"
                                name="any_deviation"
                                value={experiment.any_deviation ?? ""}
                                checked={any_deviation}
                                onChange={handleDeviationCheckboxChange}
                                className="checkboxnew"
                            />
                            <label htmlFor="any_deviation" className="block text-gray-700 font-bold ml-2 checkboxnew-label"></label>
                        </div>
                        {any_deviation && (
                            <textarea
                                name='deviation_description'
                                value={experiment.deviation_description ?? ""}
                                onChange={(e) => {
                                    handleInputChange({ deviation_description: e?.target?.value });
                                }}
                                className="border border-gray-300 p-2 rounded w-full"
                                placeholder="Describe the deviation"
                            />
                        )}
                    </div>

                    {/* SOP Upload */}
                    <div className="mb-4">
                        <label htmlFor="sopPdfs" className="block text-black-700 font-bold mb-1">
                            SOP Upload:
                        </label>
                        <button
                            type="button"
                            onClick={() => sopInputRef.current.click()}
                            className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                        >
                            Upload PDFs
                        </button>
                        <input
                            type="file"
                            ref={sopInputRef}
                            onChange={handleSopFileChange}
                            className="hidden"
                            accept="application/pdf"
                            multiple
                        />
                        <div className="mt-2">
                            {experiment && sopPdfs.length > 0 && sopPdfs.map((pdf, index) => (
                                <div key={index} className="flex items-center mb-2">
                                    <FaFilePdf className="text-red-500 mr-2" />
                                    <span className="underline cursor-pointer" onClick={() => selectedOpenPdf(pdf)}>
                                        {pdf}
                                    </span>
                                    <button
                                        type="button"
                                        className="ml-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                        onClick={() => handleRemovePdf(pdf, 'sop')}
                                    >
                                        <IoCloseOutline />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Result Images */}
                    <div className="mb-4">
                        <label htmlFor="resultImages" className="block text-black-700 font-bold mb-1">
                            Result Images:
                        </label>
                        <button
                            type="button"
                            onClick={() => imageInputRef.current.click()}
                            className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                        >
                            Upload Images
                        </button>
                        {(!resultImages || resultImages.length === 0 || resultImages == null) && (
                            <p className="text-green-700 text-xs" style={{ color: "#7FB541" }}>
                                Up to 10 files are allowed to be uploaded with the maximum file size
                                of 20MB per file. Extensions allowed are .jpeg, .png, and .webp.
                            </p>)}
                        <input
                            type="file"
                            ref={imageInputRef}
                            onChange={handleImageFileChange}
                            className="hidden"
                            accept="image/jpeg,image/png,image/webp"
                            multiple
                        />
                        <div className="mt-2">
                            {(resultImages.length > 0) && (
                                <div style={containerStyles}>
                                    <ImageSlider
                                        imagesList={resultImages.map((image) => ({
                                            url: getObjUrl(filesToUpload.find((i => i?.name == image))) ?? `${AppConfig.baseUrl}/uploads/${image}`,
                                        }))}
                                    />
                                </div>
                            )}
                            <div className="flex flex-wrap gap-2 mt-2">
                                {experiment && resultImages.length > 0 && resultImages.map((image, index) => (
                                    <div key={index} className="relative">
                                        <img
                                            src={`${AppConfig.baseUrl}/uploads/${image}`}
                                            onError={(e) => {
                                                e.target.src = URL.createObjectURL(filesToUpload.find((i => i?.name == image))) ?? ""
                                            }}
                                            className="w-20 h-20 object-cover" />
                                        <button
                                            type="button"
                                            className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                            onClick={() => handleRemoveImage(image)}
                                        >
                                            <IoCloseOutline />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>



                    {/* Date of Completion */}
                    <div className="mb-4">
                        <label htmlFor="date_of_completion" className="block text-black-700 font-bold mb-1">
                            Date of Completion:
                        </label>
                        <input
                            type="date"
                            id="date_of_completion"
                            name="date_of_completion"
                            value={experiment.date_of_completion ?? ""}
                            onChange={(e) => handleInputChange({ date_of_completion: e.target.value })}
                            className="border border-gray-300 p-2 rounded w-full"
                        />
                    </div>

                    {/* Clone Confirmation */}
                    <div className="mb-4">
                        <h3 className="text-lg font-bold mb-2">Clone Confirmation</h3>
                        <div className="flex space-x-4">
                            <div className="flex-1">
                                <label htmlFor="date_of_sending_dna" className="block text-black-700 font-bold mb-1">
                                    Date of Sending DNA Sample:
                                </label>
                                <input
                                    type="date"
                                    id="date_of_sending_dna"
                                    name="date_of_sending_dna"
                                    value={experiment.date_of_sending_dna ?? ""}
                                    onChange={(e) => handleInputChange({ date_of_sending_dna: e.target.value })}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                            </div>
                            <div className="flex-1">
                                <label htmlFor="date_of_result_receipt" className="block text-black-700 font-bold mb-1">
                                    Date of Result Receipt:
                                </label>
                                <input
                                    type="date"
                                    id="date_of_result_receipt"
                                    name="date_of_result_receipt"
                                    value={experiment.date_of_result_receipt ?? ""}
                                    onChange={(e) => handleInputChange({ date_of_result_receipt: e.target.value })}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                            </div>
                        </div>
                        {/* Sequence Analysis Report */}
                        <div className="mt-4">
                            <label htmlFor="sequenceReportPdfs" className="block text-black-700 font-bold mb-1">
                                Sequence Analysis Report:
                            </label>
                            <button
                                type="button"
                                onClick={() => sequenceAnalysisInputRef.current.click()}
                                className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                            >
                                Upload PDFs
                            </button>
                            <input
                                type="file"
                                ref={sequenceAnalysisInputRef}
                                onChange={handleSequenceAnalysisFileChange}
                                className="hidden"
                                accept="application/pdf"
                                multiple
                            />
                            <div className="mt-2">
                                {experiment && sequenceAnalysisPdfs.length > 0 && sequenceAnalysisPdfs.map((pdf, index) => (
                                    <div key={index} className="flex items-center mb-2">
                                        <FaFilePdf className="text-red-500 mr-2" />
                                        <span className="underline cursor-pointer" onClick={() => selectedOpenPdfReport(pdf)}>
                                            {pdf}
                                        </span>
                                        <button
                                            type="button"
                                            className="ml-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                            onClick={() => handleRemovePdf(pdf, 'sequenceAnalysis')}
                                        >
                                            <IoCloseOutline />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="clone_confirmation_conclusion" className="block text-black-700 font-bold mb-1">
                                Clone Confirmation Conclusion:
                            </label>
                            <select
                                id="clone_confirmation_conclusion"
                                name="clone_confirmation_conclusion"
                                value={experiment.clone_confirmation_conclusion ?? ""}
                                onChange={(e) => handleInputChange({ clone_confirmation_conclusion: e.target.value })}
                                className="border border-gray-300 p-2 rounded w-full"
                            >
                                <option value="">Select</option>
                                <option value="Success">Success</option>
                                <option value="Failure">Failure</option>
                            </select>
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleSave}
                            className="p-2 w-24 font-bold text-white rounded"
                            style={{ background: '#7FB541', borderColor: 'transparent' }}
                        >
                            Save
                        </button>
                    </div>

                    {selectedPdfUrl && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-8 rounded-lg w-3/4 max-h-screen overflow-y-auto">
                                <iframe
                                    src={selectedPdfUrl}
                                    className="w-full h-96"
                                    title="PDF Viewer"
                                ></iframe>
                                <div className="flex justify-end mt-4">
                                    <button
                                        onClick={() => setSelectedPdfUrl(null)}
                                        className="bg-gray-500 text-white py-2 px-4 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )}
        </>
    );
};

export default ExperimentDetail;
