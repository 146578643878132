import React, { useState } from 'react';
import ImageLoader from '../ImageLoader';
import Select from 'react-select';
import GeneTable from '../GeneTable';
import ProteinGeneTable from '../ProteinGeneTable';
const ProductExperiments = ({

    flag,
    showFieldsAdd,
    rowsAdd,
    handleInputChangeAdd,
    handleexpStageChangeAdd,
    handleRemoveExperimentAdd,
    isLoadingAdd,
    handleAddRowAdd,
    handleSearchAdd,
    handleSearchProteinAdd,
    stagesAdd,
    handleCodonOptimizationAdd,
    isGeneTableOpenAdd,
    geneOptionsAdd,
    currentRowIndexAdd,
    handleSelectedGeneChangeAdd,
    handleCloseGeneTableModalAdd,
    isProteinTableOpenAdd,
    proteinGeneOptionsAdd,
    handleSelectedProteinGeneChangeAdd,
    handleCloseProtienTableModalAdd,
    handleExperimentDetailsAdd,
    isGeneLoadOpenAdd,
    handleVirtualCloneConstruct,
    handleGeneSynthesisAdd,

    editedProductEdit,
    setEditedProductEdit,
    handleSearchEdit,
    handleSearchProteinEdit,
    currentRowIndexEdit,
    stagesEdit,
    handleAddRowEdit,
    handleExperimentDetailsEdit,
    handleCodonOptimizationEdit,
    handleDeleteExperimentRowEdit,
    geneOptionsEdit,
    selectedGeneEdit,
    handleSelectedGeneChangeEdit,
    handleCloseGeneTableModalEdit,
    isGeneTableOpenEdit,
    proteinGeneOptionsEdit,
    selectedProteinGene,
    handleSelectedProteinGeneChangeEdit,
    handleCloseProtienTableModalEdit,
    isProteinTableOpenEdit,
    isGeneLoadOpenEdit,
    handleGeneSynthesisEdit
}) => {
    return (

        <>

            <div className="flex justify-end items-center mb-4">
                <button
                    type="button"
                    onClick={flag === 0 ? handleAddRowAdd : handleAddRowEdit}
                    className="text-green-600 hover:text-green-700 px-3 py-2 rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                >
                    <ImageLoader name="plus-icon.svg" className="h-6 w-6 mr-2" alt="Add" title="Add" />
                    Add Experiment
                </button>
            </div>
            <div>
                {flag === 0 ? (
                    <>
                        {showFieldsAdd && (
                            <div className="grid grid-cols-1 gap-4">
                                <div className="grid grid-cols-8 gap-2 items-center font-bold ">
                                    <div className="block font-bold mb-1">Experiment Name</div>
                                    <div className="block font-bold mb-1">Common Name</div>
                                    <div className="block font-bold mb-1">Scientific Name</div>
                                    <div className="block font-bold mb-1">Gene</div>
                                    <div className="block font-bold mb-1">Protein</div>
                                    <div className="block font-bold mb-1">Stages</div>
                                    <div className="block font-bold mb-1">Action</div>
                                </div>
                                {rowsAdd.map((row, index) => (
                                    <div key={row.id} className="grid grid-cols-8 gap-2 items-center cursor-pointer hover:bg-gray-100 p-1 rounded">
                                        {/* Experiment Name */}
                                        <div className="input-container col-span-1">
                                            {/* <label htmlFor={`experimentName-${row.id}`} className="block font-bold mb-1">
                                             Experiment Name:
                                             </label> */}
                                            <input
                                                type="text"
                                                id={`experimentName-${row.id}`}
                                                name="experimentName"
                                                placeholder="Enter experiment name"
                                                value={row.experimentName}
                                                onChange={(e) => handleInputChangeAdd(row.id, e)}
                                                required
                                                className="border border-gray-300 p-2 rounded w-full"
                                            />
                                        </div>

                                        {/* Common Name */}
                                        <div className="input-container col-span-1">
                                            {/* <label htmlFor={`commonName-${row.id}`} className="block font-bold mb-1">
                                           Common Name:
                                           </label> */}
                                            <input
                                                type="text"
                                                id={`commonName-${row.id}`}
                                                name="commonName"
                                                placeholder="Enter common name"
                                                value={row.commonName}
                                                onChange={(e) => handleInputChangeAdd(row.id, e)}
                                                required
                                                className="border border-gray-300 p-2 rounded w-full"
                                            />
                                        </div>

                                        {/* Scientific Name */}
                                        <div className="input-container col-span-1">
                                            {/* <label htmlFor={`scientificName-${row.id}`} className="block font-bold mb-1">
                                           Scientific Name:
                                           </label> */}
                                            <input
                                                type="text"
                                                id={`scientificName-${row.id}`}
                                                name="scientificName"
                                                placeholder="Enter scientific name"
                                                value={row.scientificName}
                                                onChange={(e) => handleInputChangeAdd(row.id, e)}
                                                required
                                                className="border border-gray-300 p-2 rounded w-full"
                                            />
                                        </div>

                                        {/* Target Gene */}
                                        <div className="input-container col-span-1 relative">
                                            {/* <label htmlFor={`targetGene-${row.id}`} className="block font-bold mb-1">
                                            Gene:
                                            </label> */}
                                            <div className="flex">
                                                <input
                                                    type="text"
                                                    id={`targetGene-${row.id}`}
                                                    name="selectedGene"
                                                    placeholder="Search for a gene"
                                                    value={row.selectedGene}
                                                    onChange={(e) => handleInputChangeAdd(row.id, e)}
                                                    required
                                                    className="border border-gray-300 p-2 rounded-l w-full"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => handleSearchAdd(row.id)}
                                                    className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                                                    style={{
                                                        backgroundColor: '#6EB724',
                                                        borderRadius: '0 4px 4px 0',
                                                        width: '2.5rem',
                                                        height: '2.7rem',
                                                        marginTop: '1px'
                                                    }}
                                                >
                                                    <ImageLoader
                                                        className="imgFirst img-fluid"
                                                        name="search-icon.svg"
                                                        alt="Search"
                                                        title="Search"
                                                        style={{ width: '1.25rem', height: '1.25rem' }}
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                        {/* Protein Gene */}
                                        <div className="input-container col-span-1 relative">
                                            {/* <label htmlFor={`proteinGene-${row.id}`} className="block font-bold mb-1">
                                          Protein:
                                         </label> */}
                                            <div className="flex items-center">
                                                <div className="flex relative w-full">
                                                    <input
                                                        type="text"
                                                        id={`proteinGene-${row.id}`}
                                                        name="selectedProteinGene"
                                                        placeholder="Search for protein"
                                                        value={row.selectedProteinGene}
                                                        onChange={(e) => handleInputChangeAdd(row.id, e)}
                                                        required
                                                        className="border border-gray-300 p-2 rounded-l w-full"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleSearchProteinAdd(row.id)}
                                                        className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                                                        style={{
                                                            backgroundColor: '#6EB724',
                                                            borderRadius: '0 4px 4px 0',
                                                            width: '2.5rem',
                                                            height: '2.75rem',
                                                        }}
                                                    >
                                                        <ImageLoader
                                                            className="imgFirst img-fluid"
                                                            name="search-icon.svg"
                                                            alt="Search"
                                                            title="Search"
                                                            style={{ width: '1.25rem', height: '1.25rem' }}
                                                        />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                        {/* stages Name */}
                                        <div className="input-container col-span-1">
                                            <Select
                                                id={`expStage-${row.id}`}
                                                name="expStage"
                                                value={stagesAdd.find((stageOption) => stageOption.value === row.expStage)}
                                                onChange={(e) => handleexpStageChangeAdd(row.id, e)}
                                                options={stagesAdd}
                                                required
                                            />
                                        </div>


                                        {/* Actions */}
                                        <div className="input-container col-span-1 flex items-center justify-between">
                                        <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleExperimentDetailsAdd(row);
                                                }}
                                                className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                            >
                                                Experiment Details
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleVirtualCloneConstruct(row);
                                                }}
                                                className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                            >
                                                VirtualClone Construct
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleGeneSynthesisAdd(row);
                                                }}
                                                className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                            >
                                                Gene Sythesis
                                            </button>
                                           
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCodonOptimizationAdd(row.id, row.experimentName);
                                                }}
                                                className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                            >
                                                Codon Optimization
                                            </button>

                                            <button
                                                type="button"
                                                onClick={() => handleRemoveExperimentAdd(row.id)}
                                                className="text-red-600 hover:text-red-700 px-1.5 py-0.75 text-sm rounded-full flex items-center border border-red-600 hover:border-red-700 mr-2"
                                            >
                                                Delete
                                            </button>
                                        </div>

                                    </div>
                                    // </div>
                                ))}

                                {/* Collapsible Gene Table */}
                                {isGeneTableOpenAdd && (
                                    <GeneTable
                                        genes={geneOptionsAdd}
                                        selectedGene={rowsAdd[currentRowIndexAdd]?.selectedGene || ''}
                                        onSelectedGeneChange={(geneId) => handleSelectedGeneChangeAdd(geneId, currentRowIndexAdd)}
                                        // onCloseTable={() => setIsGeneTableOpen(false)}
                                        onClose={handleCloseGeneTableModalAdd}

                                    />

                                )}

                                {/* Collapsible Protein Gene Table */}
                                {isProteinTableOpenAdd && (
                                    <div className="relative">
                                        <ProteinGeneTable
                                            proteingenes={proteinGeneOptionsAdd}
                                            selectedProteinGene={rowsAdd[currentRowIndexAdd]?.selectedProteinGene || ''}
                                            onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChangeAdd(proteinGeneId, currentRowIndexAdd)}
                                            onClose={handleCloseProtienTableModalAdd}
                                            isLoading={isLoadingAdd}
                                        />
                                    </div>
                                )}
                                <div className="transition-all duration-300 max-h-96">
                                    {isGeneLoadOpenAdd && (
                                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
                                                {/* Loader */}
                                                <div className="loader-gene mb-4"></div>
                                                <p className="text-lg font-semibold">Fetching Gene Sequence</p>
                                            </div>
                                        </div>

                                    )}
                                </div>


                            </div>
                        )}
                    </>

                ) : (
                    <>
                        <div className="grid grid-cols-1 gap-4">
                            <div className="grid grid-cols-8 gap-2 items-center font-bold">
                                <div className="col-span-1 font-bold">Experiment Name</div>
                                <div className="col-span-1 font-bold">Common Name</div>
                                <div className="col-span-1 font-bold">Scientific Name</div>
                                <div className="col-span-1 font-bold">Gene</div>
                                <div className="col-span-1 font-bold">Protein</div>
                                <div className="col-span-1 font-bold">Stages</div>
                                <div className="col-span-1 font-bold">Action</div>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                {/* Map through each experiment */}
                                {editedProductEdit.product_experiments && editedProductEdit.product_experiments.filter(experiment => !experiment.is_deleted)
                                    .map((experiment, index) => (
                                        <div key={experiment.id || index} className="grid grid-cols-8 gap-2">

                                            {/* Experiment Name */}
                                            <div className="input-container col-span-1">
                                                {/* <label htmlFor={`experimentName-${experiment.id || index}`} className="block font-bold mb-1">
															Experiment Name:
														</label> */}
                                                <input
                                                    type="text"
                                                    id={`experimentName-${experiment.id || index}`}
                                                    name={`experimentName-${experiment.id || index}`}
                                                    placeholder="Enter Experiment name"
                                                    value={experiment.experiment_name || ''}
                                                    onChange={(e) => {
                                                        const updatedExperiments = editedProductEdit.product_experiments.map((exp, idx) => {
                                                            if (exp.id === experiment.id) {
                                                                return { ...exp, experiment_name: e.target.value };
                                                            }
                                                            return exp;
                                                        });
                                                        setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
                                                    }}
                                                    required
                                                    className="border border-gray-300 p-2 rounded w-full"
                                                />
                                            </div>

                                            {/* Common Name */}
                                            <div className="input-container col-span-1">
                                                {/* <label htmlFor={`commonName-${experiment.id || index}`} className="block font-bold mb-1">
															Common Name:
														</label> */}
                                                <input
                                                    type="text"
                                                    id={`commonName-${experiment.id || index}`}
                                                    name={`commonName-${experiment.id || index}`}
                                                    placeholder="Enter Common name"
                                                    value={experiment.common_name}
                                                    onChange={(e) => {
                                                        const updatedExperiments = editedProductEdit.product_experiments.map((exp, idx) => {
                                                            //..console.log(editedProductEdit.product_experiments, "index");
                                                            if (exp.id === experiment.id) {
                                                                return { ...exp, common_name: e.target.value };
                                                            }
                                                            return exp;
                                                        });
                                                        setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
                                                    }}
                                                    required
                                                    className="border border-gray-300 p-2 rounded w-full"
                                                />
                                            </div>

                                            {/* Scientific Name */}
                                            <div className="input-container col-span-1">
                                                {/* <label htmlFor={`scientificName-${experiment.id || index}`} className="block font-bold mb-1">
															Scientific Name:
														</label> */}
                                                <input
                                                    type="text"
                                                    id={`scientificName-${experiment.id || index}`}
                                                    name={`scientificName-${experiment.id || index}`}
                                                    placeholder="Enter Scientific name"
                                                    value={experiment.scientific_name}
                                                    onChange={(e) => {
                                                        const updatedExperiments = editedProductEdit.product_experiments.map((exp, idx) => {
                                                            if (exp.id === experiment.id) {
                                                                return { ...exp, scientific_name: e.target.value };
                                                            }
                                                            return exp;
                                                        });
                                                        setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
                                                    }}
                                                    required
                                                    className="border border-gray-300 p-2 rounded w-full"
                                                />
                                            </div>

                                            {/* Target Gene */}
                                            <div className="input-container col-span-1 relative">
                                                {/* <label htmlFor={`targetGene-${experiment.id || index}`} className="block font-bold mb-1">
															Gene:
														</label> */}
                                                <div className="flex">
                                                    <input
                                                        type="text"
                                                        id={`targetGene-${experiment.id || index}`}
                                                        name={`targetGene-${experiment.id || index}`}
                                                        placeholder="Search for a gene"
                                                        value={experiment.gene}
                                                        onChange={(e) => {
                                                            const updatedExperiments = editedProductEdit.product_experiments.map((exp, idx) => {
                                                                if (exp.id === experiment.id) {
                                                                    return { ...exp, gene: e.target.value };
                                                                }
                                                                return exp;
                                                            });
                                                            setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
                                                        }}
                                                        required
                                                        className="border border-gray-300 p-2 rounded-l w-full"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleSearchEdit(experiment.id)}
                                                        className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                                                        style={{
                                                            backgroundColor: '#6EB724',
                                                            borderRadius: '0 4px 4px 0',
                                                            width: '2.5rem',
                                                            height: '2.7rem',
                                                            marginTop: '1.7px',
                                                        }}
                                                    >
                                                        <ImageLoader
                                                            className="imgFirst img-fluid"
                                                            name="search-icon.svg"
                                                            alt="Search"
                                                            title="Search"
                                                            style={{ width: '1.25rem', height: '1.25rem' }}
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="input-container col-span-1 relative">
                                                {/* <label htmlFor={`proteinGene-${experiment.id || index}`} className="block font-bold mb-1">
															Protein:
														</label> */}
                                                <div className="flex items-center">
                                                    <div className="flex relative w-full">
                                                        <input
                                                            type="text"
                                                            id={`proteinGene-${experiment.id || index}`}
                                                            name={`proteinGene-${experiment.id || index}`}
                                                            placeholder="Search for protein"
                                                            value={experiment.protein}
                                                            onChange={(e) => {
                                                                const updatedExperiments = editedProductEdit.product_experiments.map((exp, idx) => {
                                                                    if (exp.id === experiment.id) {
                                                                        return { ...exp, protein: e.target.value };
                                                                    }
                                                                    return exp;
                                                                });
                                                                setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
                                                            }}
                                                            required
                                                            className="border border-gray-300 p-2 rounded-l w-full"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => handleSearchProteinEdit(experiment.id)}
                                                            className="absolute inset-y-0 right-0 flex items-center justify-center p-2 border-none"
                                                            style={{
                                                                backgroundColor: '#6EB724',
                                                                borderRadius: '0 4px 4px 0',
                                                                width: '2.5rem',
                                                                height: '2.8rem',
                                                            }}
                                                        >
                                                            <ImageLoader
                                                                className="imgFirst img-fluid"
                                                                name="search-icon.svg"
                                                                alt="Search"
                                                                title="Search"
                                                                style={{ width: '1.25rem', height: '1.25rem' }}
                                                            />
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* Experiment Stage */}
                                            <div className="input-container col-span-1">
                                                <Select
                                                    id={`expStage-${experiment.id}`}
                                                    name={`expStage-${experiment.id}`}
                                                    value={stagesEdit.find((stageOption) => stageOption.value === experiment.exp_stage)}  // Matching the exp_stage value
                                                    onChange={(e) => {
                                                        const updatedExperiments = editedProductEdit.product_experiments.map((exp) => {
                                                            if (exp.id === experiment.id) {
                                                                return { ...exp, exp_stage: e.value };  // Update exp_stage with selected value
                                                            }
                                                            return exp;
                                                        });
                                                        setEditedProductEdit({ ...editedProductEdit, product_experiments: updatedExperiments });
                                                    }}
                                                    options={stagesEdit}  // List of stage options
                                                    required
                                                />
                                            </div>

                                            {/* Action */}
                                            <div className="input-container col-span-1 flex items-center justify-between">
                                            <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleExperimentDetailsEdit(experiment);
                                                    }}
                                                    className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                                >
                                                    Experiment Details
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleVirtualCloneConstruct(experiment);
                                                    }}
                                                    className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                                >
                                                    VirtualClone Construct
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleGeneSynthesisEdit(experiment);
                                                    }}
                                                    className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                                >
                                                    Gene Sythesis
                                                </button>
                                               
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCodonOptimizationEdit(experiment, experiment.experimentName);
                                                    }}
                                                    className="text-green-600 hover:text-green-700 px-1.5 py-0.75 text-xs rounded-full flex items-center border border-green-600 hover:border-green-700 mr-2"
                                                >
                                                    Codon Optimization
                                                </button>

                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteExperimentRowEdit(index);
                                                    }}
                                                    className="text-red-600 hover:text-red-700 px-1.5 py-0.75 text-sm rounded-full flex items-center border border-red-600 hover:border-red-700 mr-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>

                                        </div>
                                    ))}
                            </div>
                        </div>


                        {/* Collapsible Gene Table */}
                        <div className="transition-all duration-300 max-h-96">
                            {isGeneTableOpenEdit && (
                                <GeneTable genes={geneOptionsEdit}
                                    selectedGene={selectedGeneEdit}
                                    onSelectedGeneChange={(geneId) => handleSelectedGeneChangeEdit(geneId, currentRowIndexEdit)}
                                    onClose={handleCloseGeneTableModalEdit}
                                // onClose={() => {
                                //   setTimeout(() => {
                                //handleCloseGeneTableModalEdit();
                                //  }, 2000); // 30000 milliseconds =  seconds
                                //}}
                                />
                            )}
                            {/* Collapsible Protein Gene Table */}
                            <div className="transition-all duration-300 max-h-96">
                                {isProteinTableOpenEdit && (
                                    <ProteinGeneTable proteingenes={proteinGeneOptionsEdit}
                                        selectedProteinGene={selectedProteinGene}
                                        onSelectedProteinGeneChange={(proteinGeneId) => handleSelectedProteinGeneChangeEdit(proteinGeneId, currentRowIndexEdit)}
                                        onClose={handleCloseProtienTableModalEdit} />
                                )}
                            </div>
                            <div className="transition-all duration-300 max-h-96">
                                {isGeneLoadOpenEdit && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
                                            {/* Loader */}
                                            <div className="loader-gene mb-4"></div>
                                            <p className="text-lg font-semibold">Fetching Gene Sequence</p>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>


        </>
    );
}
export default ProductExperiments;