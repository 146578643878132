import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConfig from "../AppConfig";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageLoader from "./ImageLoader";
import { addProducts } from "../redux/productsSlice";
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import apiService from "../services/apiService";
import '../styles/_dropdown.scss'
import stages from "../assets/data/stage";
import tools from "../assets/data/tools";
import hosts from "../assets/data/host";
import Footer from "./Footer";
import ProductInfo from "./product/ProductInfo";
import ProductHost from "./product/ProductHost";
import ProductExperiments from "./product/ProductExperiments";
import CodonOptimization from "./product/CodonOptimization";
import ExperimentDetail from "./product/ExperimentDetail";
import VirtualCloneConstruct from "./product/VirtualCloneConstruct";
import GeneSynthesis from "./product/GeneSynthesis";

const AddProductForm = () => {
  const [productName, setProductName] = useState("");
  const [stage, setStage] = useState("");
  // const [expStage, setexpStage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedHost, setSelectedHost] = useState(null);
  const [objective, setObjective] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [isTestProduct, setIsTestProduct] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedExperiment, setSelectedExperiment] = useState({});
  const [pdfFiles, setPdfFiles] = useState([]);
  const [pdfFilesGene, setPdfFilesGene] = useState([]);
  const [geneSynthesisFiles, setgeneSynthesisFiles] = useState({});

  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(false);
  const fileInputRef = useRef(null);
  const pdfInputRef = useRef(null);
  const [tool, setTool] = useState("");
  const [gcPercentage, setGcPercentage] = useState('');
  const [caiScore, setCaiScore] = useState('');
  const [optimizedSequence, setOptimizedSequence] = useState('');
  const users = useSelector((store) => store.users);
  const userId = localStorage.getItem('userId');
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [selectedPdfUrlGene, setSelectedPdfUrlGene] = useState(null);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [geneOptions, setGeneOptions] = useState([]);
  const [proteinGeneOptions, setProteinGeneOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneTableOpen, setIsGeneTableOpen] = useState(false);
  const [isProteinTableOpen, setIsProteinTableOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [rowsCodon, setRowsCodon] = useState([]);
  const [showFieldsCodon, setShowFieldsCodon] = useState(false);
  const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
  const [currentExperimentName, setCurrentExperimentName] = useState('');
  const [currentCommonName, setCurrentCommonName] = useState('');
  const [isExperimentDetailModalOpen, setIsExperimentDetailModalOpen] = useState(false);
  const [isVirtualCloneConstructModalOpen, setIsVirtualCloneConstructModalOpen] = useState(false);
  const [geneSequence, setGeneSequence] = useState([]);
  const [isGeneLoadOpen, setIsGeneLoadOpen] = useState(false);
  const [isGeneSynthesisModalOpen, setIsGeneSynthesisModalOpen] = useState(false);



  useEffect(() => {
    updateExperimentInRows(selectedExperiment);

  }, [selectedExperiment]);

  const updateExperimentInRows = (experiment) => {
    const updatedExperiments = rows?.map((exp) => {
      if (exp.id === experiment.id) {
        return { ...experiment };
      }
      return exp;
    });
    setRows([...updatedExperiments]);
  }
  const navigate = useNavigate();


  const dispatch = useDispatch();
  useEffect(() => {
    if (teamLead?.value != userId) {
      setIsApproved(false);
      setSelectedSequence(false);
    }
  }, [teamLead, userId]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Filter out files that are not jpeg, png, or webp
    const allowedFiles = selectedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp"
    );

    // Limit the number of files to a maximum of 10
    if (allowedFiles.length > 10) {
      alert("Maximum 10 files allowed.");
      return;
    }

    setFiles([...files, ...allowedFiles]);
    fileInputRef.current.value = null; // Clear the input value
  };

  const getContainerStyles = () => {
    return {
      width: "300px",
      height: imagesList.length > 0 ? "280px" : "0px",
      margin: "0 auto",
      transition: "height 0.3s ease",
    };
  };
  const handlePdfFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const allowedFiles = selectedFiles.filter((file) => file.type === "application/pdf");

    if (allowedFiles.length > 10) {
      alert("Maximum 10 PDF files allowed.");
      return;
    }
    setPdfFiles([...pdfFiles, ...allowedFiles]);
    pdfInputRef.current.value = null; // Clear the input value
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    // Filter out files that are not jpeg, png, or webp
    const allowedFiles = droppedFiles.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp"
    );

    // Limit the number of files to a maximum of 10
    if (files.length + allowedFiles.length > 10) {
      alert("Maximum 10 files allowed.");
      return;
    }

    setFiles([...files, ...allowedFiles]);
  };
  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handleDeletePdf = (index) => {
    setPdfFiles((prevPdfFiles) => prevPdfFiles.filter((_, i) => i !== index));
  };





  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handlePdfBrowseClick = () => {
    pdfInputRef.current.click();
  };
  const openPdf = (pdf) => {
    const pdfUrl = URL.createObjectURL(pdf);
    setSelectedPdfUrl(pdfUrl);
    setShowPDFViewer(true); // Open PDF viewer modal
  };
  const handleHostChange = (selectedOption) => {
    setSelectedHost(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const filenames = files.map((file) => file.name);
    const pdfFilenames = pdfFiles.map((file) => file.name);


    const experimentDetails = rows.map((row) => ({
      experimentName: row.experimentName,
      commonName: row.commonName,
      scientificName: row.scientificName,
      gene: row.selectedGene,
      protein: row.selectedProteinGene,
      expStage: row.expStage,
      gene_sequence: geneSequence,
      protein_sequence: row.protein_sequence,
      experiment_type: row.experiment_type,
      trial_number: row.trial_number,
      any_deviation: row.any_deviation,
      deviation_description: row.deviation_description,
      date_of_completion: row.date_of_completion,
      date_of_sending_dna: row.date_of_sending_dna,
      date_of_result_receipt: row.date_of_result_receipt,
      clone_confirmation_conclusion: row.clone_confirmation_conclusion,
      codonOptimizations: row.codonOptimizations.map(codonRow => ({
        id: codonRow.id,
        tool: codonRow.tool,
        caiScore: codonRow.caiScore,
        gcPercentage: codonRow.gcPercentage,
        optimizedSequence: codonRow.optimizedSequence,
        selectedSequence: codonRow.selectedSequence,
      })),
      vectors: row.vectors,
      clone_size: row.clone_size,
      cloning_sites: row.cloning_sites,
      sequence_to_be_synthesized: row.sequence_to_be_synthesized,
      company: row.company,
      date_of_order: row.date_of_order,
      date_of_receipt: row.date_of_receipt,
      clone_images: row.clone_images,
      gene_synthesis_pdfs: row.gene_synthesis_pdfs,
      sop_pdfs: row.sop_pdfs,
      result_images: row.result_images,
      sequence_report_pdfs: row.sequence_report_pdfs
    }));
    // Handle form submission
    const addFormData = {
      productName,
      stage,
      objective,
      teamLead: teamLead ? teamLead.value : "",
      isTestProduct,
      isApproved,
      selectedTeamMembers: selectedTeamMembers.map((member) => member.value),
      filenames,
      pdfFilenames: pdfFilenames,
      experimentDetails
    };

    // Create form data
    const formData = new FormData();
    const photosFormData = new FormData(); // Separate FormData for photos
    const pdfFormData = new FormData();


    formData.append("productName", productName);
    formData.append("stage", stage);
    formData.append("objective", objective);
    formData.append("teamLead", teamLead ? teamLead.value : "");
    formData.append("isTestProduct", isTestProduct ? 1 : 0);
    formData.append("isApproved", isApproved ? 1 : 0);
    selectedTeamMembers.forEach((member) => {
      formData.append("selectedTeamMembers[]", member.value);
    });


    selectedTeamMembers.forEach((member) => {

      formData.append("selectedTeamMembers[]", member);

    });


    files.forEach((file) => {
      formData.append("photos", file);
      photosFormData.append("photos", file);
    });
    pdfFiles.forEach((file) => {
      formData.append("pdfs", file);
      pdfFormData.append("pdfs", file);
    });



    setIsSubmitting(true);

    try {
      const productResponse = await apiService.post(
        `/product/add`,
        addFormData
      );
      const prod_id = productResponse.data.productId;
      addFormData.id = prod_id;

      // Check if upload was successful before proceeding with product submission
      if (productResponse.status === 201) {
        if (files.length > 0) {
          const uploadResponse = await apiService.post(
            `/photos/upload/${prod_id}`,
            photosFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Handle response
          if (uploadResponse.status === 200) {
            dispatch(addProducts(addFormData));
            // onProductAdded();
            // onClose();
          } else {
            throw new Error("Failed to upload photos");
          }
        } else {
          dispatch(addProducts(addFormData));
          // onProductAdded();
          // onClose();
        }
        if (pdfFiles.length > 0) {
          const uploadPdfResponse = await apiService.post(`/pdf/upload/${prod_id}`,
            pdfFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              },
            });
          //..console.log("uploadpdfresponse",uploadPdfResponse);


          if (uploadPdfResponse.status === 200) {
            dispatch(addProducts(addFormData));
            toast.success("Product added succesfully")
            navigate('/productPage');
            // onClose();
          } else {
            throw new Error("Failed to upload PDFs");
          }
        } else {
          dispatch(addProducts(addFormData));
          toast.success("Product added succesfully")
          navigate('/productPage');
          // onClose();
        }

      } else {
        throw new Error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product")
      // Handle error if needed
    } finally {
      // Set isSubmitting to false after submission is completed (success or failure)
      setIsSubmitting(false);
    }
  };



  const handleSwitchChange = (checked) => {
    setIsTestProduct(checked ? 1 : 0);
  };
  const handleChange = (selectedOption) => {
    setTeamLead(selectedOption);
  };
  const handleTeamMembersChange = (selectedOptions) => {
    setSelectedTeamMembers(selectedOptions);
  };
  const handleStageChange = (selectedOption) => {
    setStage(selectedOption ? selectedOption.value : "");
  };
  const handleexpStageChange = (id, selectedOption) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, expStage: selectedOption.value }; // assuming selectedOption has a value property
      }
      return row;
    });
    setRows(updatedRows);
    //setexpStage(selectedOption ? selectedOption.value : "");
  };
  const handleInputChange = (id, event) => {
    const { name, value } = event.target;
    setRows(prevRows =>
      prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
    );
  };

  const handleCodonInputChange = (rowId, codonId, event) => {
    const { name, value } = event.target;
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
            ...row,
            codonOptimizations: row.codonOptimizations.map(codon =>
              codon.id === codonId ? { ...codon, [name]: value } : codon
            )
          }
          : row
      )
    );
  };


  const imagesList = files.map((file) => ({
    url: URL.createObjectURL(file),
    title: file.name,
  }));
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSearch = async (id) => {
    const index = rows.findIndex((value) => value.id === id);

    const currentSelectedGene = rows[index].selectedGene;
    if (currentSelectedGene) {
      setIsGeneTableOpen(true);
      setIsLoading(true);
      try {
        const response = await fetch(`${AppConfig.pfCharakaApiUrl}/gene?term=${encodeURIComponent(currentSelectedGene)}`);
        const data = await response.json();

        if (data.status && data.data && data.data.rows) {
          const filteredRows = data.data.rows.filter(row =>
            row.data.id.label.trim() !== '' && row.data.description.label.trim() !== ''
          );
          setGeneOptions(filteredRows);
        } else {
          setGeneOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setGeneOptions([]);
      } finally {
        setIsLoading(false);
      }
      setCurrentRowIndex(index);
      // setIsGeneTableOpen(true);
    }
  };
  const handleSearchProtein = async (id) => {
    const index = rows.findIndex((value) => value.id === id);
    const currentSelectedProtein = rows[index].selectedProteinGene;
    if (currentSelectedProtein) {
      setIsProteinTableOpen(true);
      setIsLoading(true);
      try {
        const response = await fetch(`${AppConfig.pfCharakaApiUrl}/protein?term=${encodeURIComponent(currentSelectedProtein)}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        if (data.status && data.data && data.data.rows) {
          const filteredRows = data.data.rows.filter(row =>
            row.data.id.label.trim() !== '' &&
            row.data.title.label.trim() !== '' &&
            row.data.gi.label.trim() !== '' &&
            row.data.accessionVersion.label.trim() !== ''
          );
          setProteinGeneOptions(filteredRows);
        } else {
          setProteinGeneOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setProteinGeneOptions([]);
      } finally {
        setIsLoading(false);
      }

      setCurrentRowIndex(index);
    } else {
      setProteinGeneOptions([]);
    }
  };


  const handleSelectedGeneChange = (geneId, index) => {
    fetchGeneSequence(geneId, index);
    setRows(prevRows =>
      prevRows.map((row, i) => (i === index ? { ...row, selectedGene: geneId } : row))
    );

    setIsGeneTableOpen(false);
  };
  const handleSelectedProteinGeneChange = (proteinGeneId, index) => {
    setRows(prevRows =>
      prevRows.map((row, i) => (i === index ? { ...row, selectedProteinGene: proteinGeneId } : row))
    );
    setIsProteinTableOpen(false);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { id: nanoid(), experimentName: "", commonName: "", scientificName: "", selectedGene: "", selectedProteinGene: "", expStage: "", codonOptimizations: [] },
    ]);
    setShowFields(true);
  };

  const handleAddRowCodon = (currentRowId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === currentRowId
          ? {
            ...row,
            codonOptimizations: [
              ...(row.codonOptimizations || []), // Ensure there's an array
              { id: nanoid(), tool: "", caiScore: "", gcPercentage: "", optimizedSequence: "", selectedSequence: "" }
            ]
          }
          : row
      )
    );
    setShowFieldsCodon(true);
  };



  const handleRemoveExperiment = (id) => {
    setRows((prevRows) => prevRows.filter(row => row.id !== id));
    if (rows.length <= 1) setShowFields(false); // Hide fields if no rows are left
  };

  const handleRemoveCodonOptimization = (rowId, codonId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
            ...row,
            codonOptimizations: row.codonOptimizations.filter(codon => codon.id !== codonId)
          }
          : row
      )
    );

    // Ensure only codon optimizations are cleared, not the entire experiment
    const rowToUpdate = rows.find(row => row.id === rowId);

    if (rowToUpdate && rowToUpdate.codonOptimizations.length <= 1) {
      setRows(prevRows =>
        prevRows.map(row =>
          row.id === rowId
            ? { ...row, codonOptimizations: [] } // Just clear the codon optimizations, don't remove the row
            : row
        )
      );
      setShowFieldsCodon(false); // Optional: Hide fields if no codon optimizations are left
    }
  };


  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleCloseGeneTableModal = () => {
    setIsGeneTableOpen(false);
  }
  const handleCloseProtienTableModal = () => {
    setIsProteinTableOpen(false);
  }
  const handleCodonOptimization = (rowId, experimentName) => {
    // Logic to open a modal or window
    setCurrentRowId(rowId);
    setCurrentExperimentName(experimentName);
    setIsExperimentModalOpen(true);  // Assuming you have a modal state
  };

  const handleExperimentDetails = (row) => {
    setSelectedExperiment(row);
    setIsExperimentDetailModalOpen(true);
  };

  const handleVirtualCloneConstruct = (row) => {
    //setCurrentRowId(experiment.id);
    setSelectedExperiment(row);
    setIsVirtualCloneConstructModalOpen(true);
  };

  const handleCloseVirtualCloneConstructModal = () => {
    setIsVirtualCloneConstructModalOpen(false);
  };



  const handleGeneSynthesis = (row) => {
    setSelectedExperiment(row);
    setIsGeneSynthesisModalOpen(true);
  };

  const handleCloseGeneSynthesisModal = () => {
    setIsGeneSynthesisModalOpen(false);
  };

  const handleCloseExperimentDetailModal = () => {
    setIsExperimentDetailModalOpen(false);
    //setCurrentRowId(null);
    //setCurrentExperimentName('');
    //setCurrentCommonName('');

  };
  const handleCloseModal = () => {
    setCurrentRowId(null); // Reset the current row ID
    setCurrentExperimentName(""); // Clear experiment name
    setIsExperimentModalOpen(false); // Close the modal
    setRowsCodon([]); // Clear the codon rows to avoid stale data
  };
  const handleSaveDetailModal = () => {
    // Logic to save the data

    handleCloseExperimentDetailModal();
  };

  const handleSaveModal = () => {
    // Find the row by currentRowId and update its fields
    const updatedRows = rows.map(row => {
      if (row.id === currentRowId) {
        return {
          ...row,
          tool,
          caiScore,
          gcPercentage,
          optimizedSequence,
          selectedSequence
        };
      }
      return row;
    });

    setRows(updatedRows); // Update the state with the new row data
    handleCloseModal();   // Close the modal
  };

  const fetchGeneSequence = async (geneId) => {
    try {
      setIsGeneLoadOpen(true);
      // Step 1: Fetch nucleotide_id
      const responseNucleotide = await fetch(`https://pf.charaka.tech/gene?geneId=${geneId}`, {
        method: 'POST',
      });
      const dataNucleotide = await responseNucleotide.json();

      // Ensure the response is successful and contains the necessary data
      if (dataNucleotide.status && dataNucleotide.data) {
        const nucleotideId = dataNucleotide.data.nucleotide_id;

        // Step 2: Fetch FASTA sequence using nucleotide_id
        const responseFasta = await fetch(`https://pf.charaka.tech/fasta?nucleotide_id=${nucleotideId}`, {
          method: 'POST',
        });
        const fastaData = await responseFasta.json(); // Assuming FASTA data is returned as plain text
        if (fastaData.status && fastaData.data) {
          const fastaSequence = fastaData.data.fasta;
          setGeneSequence(fastaSequence);
        } else {
          console.error("Error: fasta not found in the response.");
          setGeneSequence("No Gene Sequence Found");

        }
      } else {
        console.error("Error: Nucleotide ID not found in the response.");
      }
    } catch (error) {
      console.error("Error fetching gene sequence:", error);
    } finally {
      setIsGeneLoadOpen(false);

    }
  };



  return (
    <>
      <div className="main-container mx-auto p-8 max-w-7xl">

        <button
          type="button"
          onClick={handleBack} // Add handler function for going back
          className="border-gray-400 border text-gray-600 px-2 py-1 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mb-4 text-sm w-auto"
          style={{ width: 'fit-content' }}
        >
          <ImageLoader name="left-arrow-active.svg" className="mr-1 h-4 w-3" />
          <span className="whitespace-nowrap">Back to products</span>
        </button>
        <h2 className="text-2xl font-semibold mb-4 sticky">Add Product</h2>

        <div className="flex flex-col min-h-screen">
          <form
            className="flex flex-col flex-grow"
            onSubmit={handleSubmit}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
          >
            <div className="flex-grow">
              {currentStep === 1 && (
                <ProductInfo
                  flag={0}
                  productNameAdd={productName}
                  setProductNameAdd={setProductName}
                  objectiveAdd={objective}
                  setObjectiveAdd={setObjective}
                  teamLeadAdd={teamLead}
                  handleChangeAdd={handleChange}
                  isTestProductAdd={isTestProduct}
                  getContainerStylesAdd={getContainerStyles}
                  imagesListAdd={imagesList}
                  usersAdd={users}
                  filesAdd={files}
                  handleStageChangeAdd={handleStageChange}
                  selectedTeamMembersAdd={selectedTeamMembers}
                  handleTeamMembersChangeAdd={handleTeamMembersChange}
                  handleSwitchChangeAdd={handleSwitchChange}
                  handleBrowseClickAdd={handleBrowseClick}
                  fileInputRefAdd={fileInputRef}
                  handleFileChangeAdd={handleFileChange}
                  handleDeleteFileAdd={handleDeleteFile}
                />

              )}
              {currentStep === 2 && (
                <ProductHost
                  flag={0}
                  teamLead={teamLead}
                  setIsApproved={setIsApproved}
                  hosts={hosts}
                  pdfFiles={pdfFiles}
                  selectedHostAdd={selectedHost}
                  handleHostChangeAdd={handleHostChange}
                  hostsAdd={hosts}
                  teamLeadAdd={teamLead}
                  userIdAdd={userId}
                  isApprovedAdd={isApproved}
                  setIsApprovedAdd={setIsApproved}
                  handlePdfBrowseClickAdd={handlePdfBrowseClick}
                  pdfInputRefAdd={pdfInputRef}
                  handlePdfFileChangeAdd={handlePdfFileChange}
                  pdfFilesAdd={pdfFiles}
                  openPdfAdd={openPdf}
                  handleDeletePdfAdd={handleDeletePdf}
                />
              )}


              {currentStep === 3 && (
                <ProductExperiments
                  flag={0}
                  showFieldsAdd={showFields}
                  rowsAdd={rows}
                  handleInputChangeAdd={handleInputChange}
                  handleexpStageChangeAdd={handleexpStageChange}
                  handleRemoveExperimentAdd={handleRemoveExperiment}
                  isLoadingAdd={isLoading}
                  handleAddRowAdd={handleAddRow}
                  handleSearchAdd={handleSearch}
                  handleSearchProteinAdd={handleSearchProtein}
                  stagesAdd={stages}
                  handleCodonOptimizationAdd={handleCodonOptimization}
                  handleRemoveExperimentaAdd={handleRemoveExperiment}
                  isGeneTableOpenAdd={isGeneTableOpen}
                  geneOptionsAdd={geneOptions}
                  currentRowIndexAdd={currentRowIndex}
                  handleSelectedGeneChangeAdd={handleSelectedGeneChange}
                  handleCloseGeneTableModalAdd={handleCloseGeneTableModal}
                  isProteinTableOpenAdd={isProteinTableOpen}
                  proteinGeneOptionsAdd={proteinGeneOptions}
                  handleSelectedProteinGeneChangeAdd={handleSelectedProteinGeneChange}
                  handleCloseProtienTableModalAdd={handleCloseProtienTableModal}
                  handleExperimentDetailsAdd={handleExperimentDetails}
                  isGeneLoadOpenAdd={isGeneLoadOpen}
                  handleVirtualCloneConstruct={handleVirtualCloneConstruct}
                  handleGeneSynthesisAdd={handleGeneSynthesis}
                />
              )}
            </div>

            {/* Buttons Section at the Bottom */}
            <div className="sticky bottom-0 left-0 right-0 bg-white p-4 flex justify-end items-center">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mr-4"
                >
                  <ImageLoader name="left-arrow-active.svg" className="mr-2 h-6 w-4" />
                  Previous
                </button>
              )}

              {currentStep < 3 && (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className={`border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center ${currentStep > 1 ? 'mr-4' : ''}`}
                >
                  <ImageLoader name="right-arrow-active.svg" className="mr-2 h-6 w-4" />
                  Next
                </button>
              )}

              {currentStep === 3 && (
                <button
                  type="submit"
                  className="text-white px-4 py-2 rounded flex items-center"
                  disabled={isSubmitting}
                  style={{ background: '#7FB541', borderColor: 'transparent' }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>



          </form>

        </div>

      </div>
      <Footer></Footer>
      {isExperimentModalOpen && (
        <CodonOptimization
          flag={0}
          currentExperimentNameAdd={currentExperimentName}
          handleAddRowCodonAdd={handleAddRowCodon}
          currentRowIdAdd={currentRowId}
          teamLeadAdd={teamLead}
          userIdAdd={userId}
          rowsAdd={rows}
          toolsAdd={tools}
          handleCodonInputChangeAdd={handleCodonInputChange}
          handleRemoveCodonOptimizationAdd={handleRemoveCodonOptimization}
          handleCloseModalAdd={handleCloseModal}
          handleSaveModalAdd={handleSaveModal}
        />
      )}

      {isExperimentDetailModalOpen && (
        <ExperimentDetail
          geneSequence={geneSequence}
          selectedExperiment={selectedExperiment}
          setSelectedExperiment={setSelectedExperiment}
          handleCloseExperimentDetailModal={handleCloseExperimentDetailModal}
        />
      )}

      {isVirtualCloneConstructModalOpen && (
        <VirtualCloneConstruct
          selectedExperiment={selectedExperiment}
          setSelectedExperiment={setSelectedExperiment}
          handleCloseVirtualCloneConstructModal={handleCloseVirtualCloneConstructModal}
        />
      )}
      {isGeneSynthesisModalOpen && (
        <GeneSynthesis

          selectedExperiment={selectedExperiment}
          setSelectedExperiment={setSelectedExperiment}
          handleCloseGeneSynthesisModal={handleCloseGeneSynthesisModal}
        />
      )}




    </>

  );

};


export default AddProductForm;
