const AppConfig = {
  charakaApiUrl: "https://lab.api.charaka.tech",
  charakaLoginApiUrl: "https://dev.api.charaka.tech",
  localApiUrl: "http://localhost:3001",
  pfCharakaApiUrl:"https://pf.charaka.tech",
 baseUrl:"https://dev.lab.api.charaka.tech"
 // baseUrl:"http://localhost:3001"

};
 
export default AppConfig;